/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   CSS Reset
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General */
* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
  background: #fcfafa;
  color: #222;
}
h2,
h3 {
  font-weight: 700;
  margin-bottom: 20px;
}
h1 {
  font-weight: 900;
  font-size: 9rem;
  margin-bottom: 40px;
}
h2 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: rgb(34, 34, 34);
  letter-spacing: 0.1rem;
  padding: 3px 5px;
}
h3 {
  font-size: 2.6rem;
}
p,
a,
li,
span {
  font-size: 1.8rem;
  margin-bottom: 6px;
  line-height: 3rem;
}
@media (max-width: 575px) {
  p,
  a,
  li,
  span {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}
button {
  padding: 8px 12px;
  margin: 15px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  border: 2px solid #e0e0fd;
  border-radius: 3px;
  background: #e0e0fd;
  cursor: pointer;
}
button:hover {
  transform: scale(1.1);
  transition: 0.1s;
}
@media (max-width: 575px) {
  button {
    font-size: 1.6rem;
  }
}
a {
  color: #e0e0fd;
  text-decoration: none;
}

.section {
  background: #e0e0fd;
  width: 115px;
}

@media (max-width: 800px) {
  .section {
    width: 100%;
    text-align: center;
  }
}

.App {
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 5%;
  margin-top: 110px;
}
/*NAV*/
.nav-wrapper {
  background: rgb(252, 250, 250);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 40px 25px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  opacity: 0.95;
}
#navname {
  margin-bottom: 0px;
}

.name-wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .name-wrapper {
    display: none;
  }
}

.nav-links {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .nav-links {
    margin: 0 auto;
  }
}

.nav-link {
  margin-left: 40px;
  line-height: inherit;
}
@media (max-width: 800px) {
  .nav-link {
    margin-left: 25px;
  }
}
@media (max-width: 575px) {
  .nav-link {
    margin-left: 0;
    margin-right: 25px;
  }
}

/*Summary*/

.summary-wrapper {
  width: 100%;
  margin: 5px auto;
  display: flex;
  align-items: center;
}

@media (max-width: 800px) {
  .summary-wrapper {
    width: 100%;
    display: contents;
  }
}
.summary {
  font-size: 1.8rem;
  line-height: 3rem;
}
@media (max-width: 575px) {
  .summary {
    font-size: 1.8rem;
  }
}

.summary-text {
  margin-left: 6rem;
}

@media (max-width: 800px) {
  .summary-text {
    margin-left: 0rem;
  }
}

#top-image {
  width: 100%;
  height: 100%;
}

/*SKILLS*/

.skills-wrapper {
  display: flex;
  margin-bottom: 80px;
}

@media (max-width: 800px) {
  .skills-wrapper {
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (max-width: 575px) {
  .skills-wrapper {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px;
  }
}

#skills-span {
  font-weight: 500;
}

#skills-section {
  margin-top: 55px;
}
.skills-category {
  margin: 3rem;
}
@media (max-width: 800px) {
  .skills-category {
    width: 23%;
  }
}

@media (max-width: 575px) {
  .skills-category {
    width: 30%;
  }
}

/*Projects*/

.projects {
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0px 0px;
}
@media (max-width: 800px) {
  .projects {
    margin-bottom: 0px;
    padding: 20px 0px 20px;
  }
}

@media (max-width: 575px) {
  .projects {
    padding: 0;
    flex-direction: column-reverse;
  }
}

.project-name {
  margin-top: 2rem;
}
@media (max-width: 575px) {
  .project-name {
    text-align: center;
  }
}

.info {
  width: 50%;
}
@media (max-width: 800px) {
  .info {
    width: 75%;
  }
}
@media (max-width: 575px) {
  .info {
    width: 100%;
  }
}

.buttons {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
@media (max-width: 575px) {
  .buttons {
    justify-content: center;
  }
}

.project-images {
  width: 68%;
  display: flex;
  padding-left: 38px;
}
@media (max-width: 800px) {
  .project-images {
    width: 60%;
    margin-top: 3rem;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .project-images {
    padding-left: 0;
  }
}

.project-image {
  max-width: 500px;
  width: 100%;
  align-self: center;
}

.project-button {
  margin-right: 25px;
}

.far {
  font-size: 6.3rem;
  font-weight: 400;
}
.fab {
  font-family: "Font Awesome 5 Brands";
  font-size: 6.3rem;
  font-weight: 400;
}

.contact-wrapper {
  margin-top: 10rem;
}
.contact-link {
  margin-right: 50px;
  color: rgb(45, 45, 45);
}

.contact-icon-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .contact-icon-wrapper {
    text-align: center;
  }
}
